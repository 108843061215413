import React from 'react';
import WidgetHeader from '../WidgetHeader';
import './Hero.scss';
import { array, bool, object, string } from 'prop-types';
import SponsoredBy from '@/components/ads/sponsored/SponsoredBy';
import { useWidgetContext } from '@/components/widgets/WidgetContext';
import Hero from './Hero';
import {
	getDataType,
	getWidgetCategory,
	getThemeClassName,
	getContentListFromGalleryFolders
} from '@/components/widgets/widgetHelpers';
import './HeroWidget.scss';

const imgSizes = {
	FULL: '1280',
	HALVES: '660',
	THIRDS: '450',
	EIGHT_FOUR: '900',
	FOUR_EIGHT: '900'
};

const options = { thumbType: '16x9' };

/**
 * @function HeroWidget
 * @param {Object} props
 * @param {Object} [props.analyticsData]
 * @param {Array} props.contentList
 * @param {Object} props.criteria
 * @param {String} [props.header]
 * @param {Object} [props.headerImage]
 * @param {Boolean} props.isCurated
 * @param {String} [props.position]
 * @param {String} props.sectionType
 * @param {String} props.widgetTemplate
 * @param {Boolean} [props.authorBylineEnabled]
 * @param {Boolean} [props.timestampsEnabled]
 * @param {String} [props.pageTheme]
 * @return {React.ReactElement}
 */
const HeroWidget = ({
	contentList,
	criteria,
	header,
	headerImage,
	isCurated,
	position,
	sectionType,
	widgetTemplate,
	timestampsEnabled,
	authorBylineEnabled,
	pageTheme
}) => {
	const { isThumbLazyLoadDisabled } = useWidgetContext();
	const contentItem = contentList[0] || {};
	const width = imgSizes[sectionType] || '660';
	const { displayImage, displayTitle, reference, linkType } = contentItem;

	if (!reference || !displayImage) return null;

	const { branding } = reference;

	const analyticsData = {
		'data-widget-type': 'banner-widget',
		'data-widget-template': widgetTemplate,
		'data-widget-position': position,
		'data-widget-data-type': getDataType(criteria, isCurated),
		'data-widget-category': getWidgetCategory(criteria, isCurated)
	};

	const updatedContentList = getContentListFromGalleryFolders(contentList, 'HERO');

	return (
		<section
			className={`banner-widget widget ${getThemeClassName(pageTheme)}`}
			data-tb-region={`banner-widget ${widgetTemplate} ${position}`}
		>
			{header && <WidgetHeader title={header} thumbnail={headerImage} />}
			<Hero
				analyticsData={analyticsData}
				options={options}
				thumbnail={updatedContentList.displayImage}
				title={updatedContentList.displayTitle}
				type={updatedContentList.linkType}
				width={width}
				width2x="828"
				{...updatedContentList.reference}
				authorBylineEnabled={authorBylineEnabled}
				timestampsEnabled={timestampsEnabled}
				isThumbLazyLoadDisabled={isThumbLazyLoadDisabled}
			>
				{isCurated && branding && <SponsoredBy {...branding} />}
			</Hero>
		</section>
	);
};

HeroWidget.defaultProps = {
	options: { thumbType: '16x9' }
};

HeroWidget.propTypes = {
	contentList: array,
	criteria: object.isRequired,
	header: string,
	headerImage: object,
	isCurated: bool.isRequired,
	position: string,
	sectionType: string,
	widgetTemplate: string,
	timestampsEnabled: bool,
	authorBylineEnabled: bool,
	pageTheme: string
};

HeroWidget.displayName = 'HeroWidget';

export default HeroWidget;